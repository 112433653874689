import React, { useEffect, useState } from 'react';
import { XeleBreadcrumb } from '../../Components';
import {
  BottomBoxView,
  Header,
  MainMenuView,
  NewReleaseNotification,
  GlobalSuccessDialog,
  OpenCloseView,
} from '../../Views/Home';
import { SwitchRoute } from '../../Components/Route/SwitchRoute';
import { HomeRoutes } from '../../routes/HomeRoutes/HomeRoutes';
import {
  setLoading,
  setSideMenuIsOpenCallback,
  sideMenuIsOpenUpdate,
} from '../../Helper/Middleware.Helper';
import { config } from '../../config';
import { SuccessDialogProvider } from '../../SharedComponents';
import clsx from 'clsx';

export const OldHomeLayout = () => {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  setSideMenuIsOpenCallback(setSideMenuIsOpen);
  const [, setShowLoading] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(70);
  const [NewReleaseNotificationState, setNewReleaseNotificationState] =
    useState(false);

  const changeShowLoading = (flag) => {
    setShowLoading(flag);
  };
  setLoading(changeShowLoading);
  if (config.DisableRightClicking)
    document.addEventListener('contextmenu', (event) => event.preventDefault());

  useEffect(() => {
    try {
      const storedSession = localStorage.getItem('session');
      if (storedSession) {
        const versionFrom = JSON.parse(storedSession).versionNo;
        if (versionFrom && versionFrom !== config.version)
          setNewReleaseNotificationState(true);
      } else console.warn("'session' not found in localStorage");
    } catch (error) {
      console.error("Error  parsing 'session' from localStorage:", error);
    }
  }, []);

  return (
    <SuccessDialogProvider>
      <GlobalSuccessDialog />
      <Header headerHeightChanged={setHeaderHeight} />
      <div
        style={{ minHeight: `calc(100dvh - ${headerHeight}px)` }}
        className='container'
      >
        <MainMenuView toggleHover={setIsHover} />
        <div
          className={clsx('content-wrapper', {
            'is-open': isHover,
            'is-open-side-menu': sideMenuIsOpen,
          })}
        >
          <div className='open-close-main-layout'>
            <XeleBreadcrumb />

            <SwitchRoute routes={HomeRoutes} />
          </div>
          <BottomBoxView />
        </div>
        <OpenCloseView
          isOpen={sideMenuIsOpen}
          isOpenClicked={() => sideMenuIsOpenUpdate()}
        />
      </div>
      <NewReleaseNotification
        isOpen={NewReleaseNotificationState}
        setNewReleaseNotificationState={setNewReleaseNotificationState}
        isClicked={() => {}}
      />
    </SuccessDialogProvider>
  );
};
